/* eslint-disable no-unused-expressions */

import Glide from '@glidejs/glide'
import { rapidReady, ylkStringToObj } from '../../../../lib/utils/rapid'

const SELECTOR_PREV_BTN = '.m-finance-banner__controls--prev'
const SELECTOR_NEXT_BTN = '.m-finance-banner__controls--next'
const SELECTOR_SLIDE_LIST = '.m-finance-banner__list.glide__slides'
const SELECTOR_MARKET_ITEM = '.m-finance-banner__market'

export default (() => {
  class FinanceBanner {
    constructor({ selector }) {
      this.elem = document.querySelector(selector)
      if (!this.elem) {
        return
      }
      const { renderType } = this.elem.dataset

      // FYI perSwipe: '|' is not in the glide docs, but is supported and discussed in this issue
      // https://github.com/glidejs/glide/issues/346

      if (renderType === 'v2') {
        this.glideOptions = {
          perView: 1,
          perSwipe: '|',
          rewind: false,
          peek: { before: 0, after: 40 },
          breakpoints: {
            1440: {
              perView: 2,
              peek: { before: 0, after: 60 }
            },
            900: {
              perView: 4,
              peek: { before: 0, after: 40 }
            },
            600: {
              perView: 3,
              peek: { before: 0, after: 40 }
            },
            460: {
              perView: 2,
              peek: { before: 0, after: 100 }
            }
          }
        }
      } else {
        this.glideOptions = {
          perView: 5,
          perSwipe: '|',
          rewind: false,
          breakpoints: {
            1100: {
              perView: 4
            },
            954: {
              perView: 3
            },
            754: {
              perView: 3,
              peek: { before: 0, after: 40 }
            },
            370: {
              peek: 0,
              perView: 3
            }
          }
        }
      }
      this.glideInstance = new Glide(this.elem, this.glideOptions)
      this.prevBtn = this.elem.querySelector(SELECTOR_PREV_BTN)
      this.nextBtn = this.elem.querySelector(SELECTOR_NEXT_BTN)
      this.slideList = this.elem.querySelector(SELECTOR_SLIDE_LIST)
      this.addEventListeners()

      this.glideInstance.mount()
    }

    addEventListeners() {
      this.glideInstance.on(['mount.after', 'run'], () => {
        this.updateControlBtns()
      })

      this.nextBtn?.addEventListener('click', () => {
        this.rapidBeaconEvent(this.nextBtn)
      })

      this.prevBtn?.addEventListener('click', () => {
        this.rapidBeaconEvent(this.prevBtn)
      })

      this.slideList.addEventListener('click', e => {
        const marketItem = e.target.closest(SELECTOR_MARKET_ITEM)
        if (marketItem) {
          this.rapidBeaconEvent(marketItem)
        }
      })
    }

    rapidBeaconEvent(elem, overrides) {
      const { ylk = '' } = elem?.dataset || {}
      const ylkObj = { ...ylkStringToObj(ylk), ...overrides }
      rapidReady(rapid => {
        rapid.beaconClick(ylkObj.sec, ylkObj.slk, ylkObj.itc, ylkObj)
      })
    }

    updateControlBtns() {
      const {
        index,
        settings: { perView }
      } = this.glideInstance

      if (index === 0) {
        this.prevBtn?.setAttribute('disabled', true)
      } else {
        this.prevBtn?.removeAttribute('disabled')
      }

      if (index + perView >= this.slideList?.childElementCount) {
        this.nextBtn?.setAttribute('disabled', true)
      } else {
        this.nextBtn?.removeAttribute('disabled')
      }
    }
  }

  return FinanceBanner
})()
